import EngineModelManagerForm from '../../../../components/Admin/Engines/EngineModelManager/EngineModelManagerForm'
import { Grid, Typography, Button } from '@mui/material'
import { DoneOutline } from '@mui/icons-material'

const Step2Engine = ({ setNextStep, permissionsCheck, endQuickStart }) => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography
                    align="center"
                    variant="h5"
                    color="warning"
                    sx={{ margin: 2 }}
                >
                    If you already have a configured engine model you can:
                    {
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={endQuickStart}
                            sx={{ width: 200, marginLeft: 5 }}
                            endIcon={<DoneOutline />}
                        >
                            End Quick Start
                        </Button>
                    }
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <EngineModelManagerForm
                    row={{}}
                    formDescription={'Create New Engine Model'}
                    onClose={endQuickStart}
                    saveCache={() => {}}
                    minimizeModal={() => {}}
                    permissionCheck={permissionsCheck}
                    quickStart={true}
                />
            </Grid>
        </Grid>
    )
}
export default Step2Engine
