import { useReducer, useContext, useEffect } from 'react'
import { Grid, Typography } from '@mui/material'
import {
    UniversalToolBar,
    UniversalDataTable,
    UniversalModal,
    UniversalLoadingCircle,
    FormSection,
} from '../../../UniversalComponents'
import EngineManagerForm from './EngineManagerForm'
import AddBoxIcon from '@mui/icons-material/AddBox'
import {
    Archive as ArchiveIcon,
    DeleteForever,
    Restore,
} from '@mui/icons-material/'

import {
    getEngines,
    deactivateEngines,
    deleteEngine,
} from '../../../../apiRoutes'

import { ToastMessage } from '../../../../context/ToastPopUpContext'
import { useMinimizer } from '../../../../context/MinimizeContext'
import {
    Authentication,
    useAuth,
} from '../../../../context/AuthenticationContext'
import {
    handleActivation,
    handleDeletion,
    standardColumnMapper,
} from '../../../../utilities/functionTools'
import { permissionsCheck } from '../../../../utilities/functionTools'
import { usePermissions } from '../../../../context/AuthenticationContext'

const today = new Date().toISOString().substring(0, 10)

const EngineManager = ({ restoredData }): any => {
    const reducer = (state, newState) => ({ ...state, ...newState })
    const userPermissions = usePermissions()
    const currentUser = useAuth()
    const { setUser } = useAuth()
    const { username, activeManufacturer, userRole, userPreferences, _id } =
        currentUser.auth

    const defaultState = {
        open: restoredData.id === undefined ? false : true,
        row: restoredData.id === undefined ? {} : restoredData,
        formCache: {},
        viewDeactivated: false,
        activateData: [],
        data: [],
        engines: {
            engine: {
                columns: [
                    {
                        field: 'engineSerial',
                        headerName: 'ENGINE SERIAL',
                        width: 300,
                    },
                    {
                        field: 'manufacturerName',
                        headerName: 'MANUFACTURER',
                        width: 300,
                    },
                    {
                        field: 'engineModelName',
                        headerName: 'ENGINE MODEL',
                        width: 150,
                    },
                    {
                        field: 'calibrationFileName',
                        headerName: 'CALIBRATION FILE NAME',
                        width: 150,
                    },
                    {
                        field: 'hardwarePartNumber',
                        headerName: 'HARDWARE PART NUMBER',
                        width: 150,
                    },
                ],
                density: 'standard',
            },
        },
        density:
            userPreferences?.dataTableColumns?.engines?.engine?.density ||
            'standard',
    }
    const defaultColumnVisibility = {
        id: true,
        engineMake: true,
        engineModel: true,
        uploadAt: true,
        calibrationFileId: true,
        updatedBy: true,
        hardwarePartNumber: true,
        ecmPartNumber: true,
    }
    const setToastMessage = useContext(ToastMessage)
    const [state, setState] = useReducer(reducer, defaultState)
    const { setMinimized } = useMinimizer()

    const {
        open,
        row,
        formCache,
        viewDeactivated,
        data,
        activateData,
        engines,
        density,
    } = state

    const { engine } = engines

    const getData = async (active) => {
        try {
            const engines = await getEngines(active)
            if (engines && engines.length !== 0) {
                const filteredEngines = standardColumnMapper(
                    defaultColumns,
                    engines
                )

                setState({ data: filteredEngines })
            } else {
                setState({ data: [] })
            }
        } catch (err: any) {
            setToastMessage(err, 'error')
            console.error(err, 'error')
        }
    }

    const collectColumnData = () => {
        const engines =
            userPreferences?.dataTableColumns?.engines?.engine?.columns
        let setDensity = ''

        if (Array.isArray(engines)) {
            const mapColumns = engines.map((engine) => ({
                field: engine?.field,
                headerName: engine?.headerName,
                width: engine?.width,
                isVisible: engine.isVisible,
            }))

            const format = {
                engine: {
                    columns: mapColumns,
                    density: setDensity,
                },
            }

            setState({ ...state, engines: format })
        } else {
            console.log('No Engines Found')
        }
    }
    const submitColumnData = async () => {
        try {
            setUser({
                key: 'engines',
                value: engines,
                type: 'columnData',
            })
        } catch (err: any) {
            console.error(err, 'error')
            setToastMessage(`${err}`, 'error')
        }
    }

    const handleActivateData = () => {
        handleActivation({
            activateData,
            data,
            returnKey: 'engineSerial',
            setState,
            getData,
            route: deactivateEngines,
            viewDeactivated,
            setToastMessage,
            page: 'engines',
        })
    }

    // returnKey is the key of the value wanting to be returned.. example manufacturerName: 'Osmosis'
    // route is the API route function name being imported into index. example deactivateManufacturers
    // page is the object being defined in the API route for example const { manufacturers, updatedBy, activate } = req.body
    // manufactuers is the page

    const handleDeletionData = () => {
        handleDeletion({
            activateData,
            data,
            returnKey: 'engineSerial',
            setState,
            getData,
            route: deleteEngine,
            viewDeactivated,
            setToastMessage,
            page: 'engines',
        })
    }

    const handleDeletionCheck = () => {
        setToastMessage(
            `Are you sure you want to delete these Models?`,
            'warning',
            handleDeletionData
        )
    }

    const setDeactivatedView = () => {
        setState({ viewDeactivated: !viewDeactivated })
    }

    useEffect(() => {
        collectColumnData()
        getData(true)
        setMinimized(false, restoredData.id, true)
    }, [])

    useEffect(() => {
        getData(!viewDeactivated)
    }, [viewDeactivated, activeManufacturer, userRole])

    const setModalVisbility = async (tableRow: any, newData: any) => {
        if (tableRow?.engineModel) {
            setState({ row: tableRow, open: !open })
        } else {
            setState({ row: defaultState.row, open: !open })
        }
        if (newData !== undefined) {
            return getData(!viewDeactivated)
        }
    }

    const minimizeModal = () => {
        setMinimized(true, {
            title: formCache.engine
                ? `Edit: ${formCache.engine}`
                : 'Add New Engine',
            link: '/admin/engines',
            data: formCache.id
                ? { ...formCache, tab: 'engines' }
                : { ...formCache, id: 'newModel', tab: 'engines' },
            id: formCache.id ? formCache.id : 'newModel',
        })
        setState({ open: false })
    }

    const defaultColumns = [
        'engineSerial',
        'engineModel.manufacturer.manufacturerName',
        'engineModel.engineModelName',
        'calibration.calibrationFileName',
        'hardwarePartNumber',
        'engineModel.engineModelYear',
        'engineModel.manufacturer._id',
        'calibration._id',
        'updatedAt',
        'updatedBy',
        'ecmPartNumber',
        'archived',
        'createdAt',
    ]

    const buttons = [
        {
            buttonName: 'Add New Engine',
            buttonIcon: <AddBoxIcon />,
            buttonFunction: () => setState({ open: !open }),
            disabled: permissionsCheck(userPermissions, 'Engines'),
        },
        {
            buttonName: !viewDeactivated ? 'Deactivate' : 'Activate',
            buttonIcon: <ArchiveIcon />,
            buttonFunction: handleActivateData,
            disabled: permissionsCheck(userPermissions, 'Engines'),
        },
        {
            buttonName: viewDeactivated
                ? 'View Active Engines'
                : 'View Deactivated Engines',
            buttonIcon: <Restore />,
            buttonFunction: setDeactivatedView,
        },
        {
            buttonName: 'Delete',
            buttonIcon: <DeleteForever />,
            buttonFunction: handleDeletionCheck,
            disabled: permissionsCheck(userPermissions, 'Engines'),
            display: !viewDeactivated ? 'none' : null,
        },
        // {
        //     buttonName: 'Export',
        //     buttonIcon: <ArrowOutwardIcon />,
        //     buttonFunction: () => {},
        // },
    ]

    const handleUpdatedColumnsChange = (updatedColumns) => {
        const format = updatedColumns.map((col) => ({
            field: col.field,
            headerName: col.headerName,
            width: col.width,
            isVisible: col.isVisible,
        }))

        const setDensity = state.density

        const setData = { engine: { columns: format, density: setDensity } }

        setState({
            engines: setData,
        })
    }

    const handleUpdatedVisibility = (columnVisibilityModel) => {
        const updatedVisibility = engines?.columns.reduce((acc, col) => {
            if (col.field in columnVisibilityModel) {
                acc[col.field] = {
                    ...col,
                    isVisible: columnVisibilityModel[col.field],
                }
            } else {
                acc[col.field] = col
            }

            return acc
        }, {})

        const updatedColumns = Object.values(updatedVisibility)

        const setDensity = state.density

        const setData = {
            engine: { columns: updatedColumns, density: setDensity },
        }

        setState({ ...state, engines: setData })
    }

    const handleDensityChange = (newDensity) => {
        setState({
            density: newDensity,
            engines: {
                engine: {
                    ...engine,
                    density: newDensity,
                },
            },
        })
    }

    return (
        <Grid container>
            <Grid container>
                <UniversalToolBar buttons={buttons} />
            </Grid>
            <Grid container sx={{ marginTop: 2, marginBottom: -10 }}>
                <FormSection>
                    <Grid item xs={12}>
                        <Typography
                            variant="h4"
                            color={'primary'}
                            align="center"
                            sx={{ marginTop: -1 }}
                        >
                            {!viewDeactivated
                                ? 'Active Engines'
                                : 'Deactivated Engines'}
                        </Typography>
                    </Grid>
                </FormSection>
            </Grid>

            <Grid item xs={12}>
                <UniversalLoadingCircle
                    data={data}
                    reloadFunction={
                        !viewDeactivated
                            ? () => getData(true)
                            : () => getData(false)
                    }
                    customTimeoutMessage={
                        !!viewDeactivated && 'No Deactivated Engines Available'
                    }
                >
                    <UniversalDataTable
                        data={data}
                        apiDensity={density}
                        apiColumns={engines?.engine?.columns}
                        savePreferences={() => submitColumnData()}
                        visibleColumns={[]}
                        getRowData={(tableRow) =>
                            setModalVisbility(tableRow, undefined)
                        }
                        onRowSelectionModelChange={(newSelection) => {
                            setState({
                                activateData: newSelection,
                            })
                        }}
                        onUpdatedColumnsChange={handleUpdatedColumnsChange}
                        getColumnHeaderVisibility={handleUpdatedVisibility}
                        onDensityChange={handleDensityChange}
                    />
                </UniversalLoadingCircle>

                <UniversalModal
                    title={`${
                        row.engineModel
                            ? `Edit Engine Serial: ${row.engineSerial}`
                            : 'Add New Engine Model'
                    }`}
                    timeStamp={`${
                        row.updatedBy
                            ? ` ${
                                  row.updatedBy
                                      ? ` Modified By ${row.updatedBy}`
                                      : ''
                              } ${
                                  row.updatedAt
                                      ? `| Modified Date ${row.updatedAt.substring(
                                            0,
                                            10
                                        )}`
                                      : ''
                              }  `
                            : ` Modified By: ${username} | Modified Date: ${today} `
                    }`}
                    open={open}
                    onClose={(tableRow) =>
                        setModalVisbility(tableRow, undefined)
                    }
                    wrapperStyle={{
                        margin: 'auto',
                        width: '90%',
                        marginTop: 5,
                    }}
                    onMinimize={minimizeModal}
                    draggable={true}
                    minimizable={true}
                >
                    <EngineManagerForm
                        row={row}
                        formDescription={'Create New Engine Model'}
                        onClose={(tableRow: any, newData: any) =>
                            setModalVisbility(tableRow, newData)
                        }
                        saveCache={(data: any) => setState({ formCache: data })}
                        minimizeModal={minimizeModal}
                        permissionCheck={permissionsCheck(
                            userPermissions,
                            'Engines'
                        )}
                        quickStart={false}
                    />
                </UniversalModal>
            </Grid>
        </Grid>
    )
}

export default EngineManager
