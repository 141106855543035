import { Grid, Button, Typography } from '@mui/material'
import { FastForward } from '@mui/icons-material'
import BoatModelForm from '../../../../components/Admin/Boats/BoatModel/boatModelForm'

const Step3Boat = ({ setNextStep, permissionsCheck }) => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography
                    align="center"
                    variant="h5"
                    color="warning"
                    sx={{ margin: 2 }}
                >
                    If you already have a boat model configured you can:
                    {
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => setNextStep(4)}
                            sx={{ width: 200, marginLeft: 5 }}
                            endIcon={<FastForward />}
                        >
                            Skip Step
                        </Button>
                    }
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <BoatModelForm
                    row={{}}
                    formDescription="Use this form to add a new Signal Profile within Osmosis Telematics Platform"
                    onClose={() => setNextStep(4)}
                    saveCache={() => {}}
                    permissionCheck={permissionsCheck}
                    quickStart={true}
                />
            </Grid>
        </Grid>
    )
}

export default Step3Boat
