import { useReducer, useRef, useEffect } from 'react'
import {
    Toolbar,
    IconButton,
    Box,
    Button,
    Grid,
    Typography,
    Tooltip,
} from '@mui/material'

import {
    Menu as MenuIcon,
    DynamicFeed as DynamicFeedIcon,
    HelpOutlineOutlined as HelpOutlineOutlinedIcon,
    MenuBookOutlined as MenuBookOutlinedIcon,
    Checklist as ChecklistIcon,
    Close as CloseIcon,
    Padding,
    Height,
    Bolt,
    Close,
} from '@mui/icons-material'
import NavMenuAvatar from './NavMenuAvatar'

import { useMinimizer } from '../../context/MinimizeContext'
import { useQuickStartWizard } from '../../context/QuickStartWizard'
import { NavLink } from 'react-router-dom'
import { useAuth } from '../../context/AuthenticationContext'
import { getManufacturers } from '../../apiRoutes'
import { UniversalAutoComplete } from '../UniversalComponents/UniversalFormStyles'
import { FormSection } from '../UniversalComponents'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'

const NavMenuToolbar = ({ handleDrawerToggle }) => {
    const { minimizedModals, setMinimized } = useMinimizer()
    const { setQuickStartWizard } = useQuickStartWizard()
    const { auth, setUser } = useAuth()
    const { userRole, manufacturer, activeManufacturer } = auth
    const defaultState = {
        manufacturers: [],
        manufacturerOptions: [],
    }

    const reducer = (state, newState) => ({ ...state, ...newState })
    const [state, setState] = useReducer(reducer, defaultState)
    const { manufacturers, manufacturerOptions } = state
    const getData = async () => {
        let manufacturers: any = []
        let manufacturerOptions: any = []
        if (userRole === 'Super Admin') {
            const getManufacturerData = await getManufacturers(true)
            if (getManufacturerData.length !== 0) {
                manufacturers = getManufacturerData
                manufacturerOptions = getManufacturerData.map(
                    ({ manufacturerName }) => manufacturerName
                )
            }
        } else {
            manufacturerOptions =
                manufacturer.length !== 0 &&
                manufacturer.map(({ manufacturerName }) => manufacturerName)

            manufacturers = manufacturer
        }

        setState({
            manufacturerOptions,
            manufacturers,
        })
    }

    useEffect(() => {
        getData()
    }, [])

    useEffect(() => {
        getData()
    }, [userRole, manufacturer])

    return (
        <Toolbar>
            <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: 2, display: { sm: 'none' } }}
            >
                <MenuIcon />
            </IconButton>
            <Grid container spacing={2} sx={{ marginRight: 2 }}>
                <Grid item xs={2}>
                    {(userRole === 'Super Admin' || userRole === 'Admin') &&
                        manufacturer.length > 1 && (
                            <UniversalAutoComplete
                                id="activeManufacturer"
                                options={manufacturerOptions}
                                handleChange={(e: any) => {
                                    const { name, value } = e.target

                                    const getFullManufacturer =
                                        manufacturers.find(
                                            ({ manufacturerName }) =>
                                                manufacturerName === value
                                        )

                                    setUser({
                                        key: name,
                                        value: getFullManufacturer,
                                    })
                                }}
                                value={activeManufacturer}
                                fullWidth
                                label="Active Manufacturer"
                                name="activeManufacturer"
                            />
                        )}
                </Grid>
                <Grid item xs={7} />
                <Grid item xs={3}>
                    <Button
                        fullWidth
                        style={{ height: 55 }}
                        variant="contained"
                        color="primary"
                        onClick={() => setQuickStartWizard(true)}
                        startIcon={
                            <Bolt
                                style={{
                                    fontSize: 25,
                                }}
                            />
                        }
                    >
                        Quick Start Provision Wizard
                    </Button>
                </Grid>

                {/* <Box
                sx={{
                    flexGrow: 1,
                    display: {
                        xs: 'flex',
                        sm: 'flex',
                        md: 'flex',
                        lg: 'flex',
                    },
                }}
            >
                {minimizedModals.map(({ title, link, id }) => (
                    <Box key={title}>
                        <Grid item margin={1} xs={12}>
                            <FormSection
                                reset={
                                    <Grid item xs={12}>
                                        <Tooltip key={title} title="Remove">
                                            <IconButton
                                                onClick={() =>
                                                    setMinimized(
                                                        false,
                                                        id,
                                                        true
                                                    )
                                                }
                                            >
                                                <Close />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                }
                                sx={{ padding: 2 }}
                                spacing={0}
                                titleBottom={
                                    <Grid item xs={12}>
                                        <Typography
                                            fontSize={10}
                                            style={{
                                                marginBottom: -10,
                                                marginTop: -10,
                                                marginLeft: -50,
                                            }}
                                        >
                                            {link}
                                        </Typography>
                                    </Grid>
                                }
                                titleBottomAlignment="right"
                                icon={
                                    <NavLink to={link}>
                                        <Tooltip title="Select to Open">
                                            <Button
                                                sx={{
                                                    background: 'inherit',
                                                    color: 'primary',
                                                    padding: 0,
                                                    marginTop: -5,
                                                    marginBottom: -5,
                                                }}
                                                key={`${title}.button`}
                                                onClick={() =>
                                                    setMinimized(false, id)
                                                }
                                                startIcon={
                                                    <DynamicFeedIcon
                                                        style={{
                                                            fontSize: 25,
                                                        }}
                                                    />
                                                }
                                                size="medium"
                                            >
                                                {title}
                                            </Button>
                                        </Tooltip>
                                    </NavLink>
                                }
                            />
                        </Grid>
                    </Box>
                ))}
            </Box> */}
            </Grid>
            <Grid item xs={2}>
                <NavMenuAvatar />
            </Grid>
        </Toolbar>
    )
}

export default NavMenuToolbar
