import { Autocomplete, Grid, TextField, Typography } from '@mui/material'
import { useContext, useEffect, useReducer } from 'react'
import { ToastMessage } from '../../../../context/ToastPopUpContext'
import { Authentication } from '../../../../context/AuthenticationContext'
import {
    addEngineModel,
    getManufacturers,
    getEngineModels,
} from '../../../../apiRoutes'
import { useMinimizer } from '../../../../context/MinimizeContext'
import UniversalTagToolbar from '../../../UniversalComponents/UniversalTagToolbar'
import { FormSection, SaveButton } from '../../../UniversalComponents'
import responseError from '../../../../context/responseError/responseError'
import { basicValidator } from '../../../../utilities/functionTools'

const EngineModelManagerForm = ({
    row,
    formDescription,
    onClose,
    saveCache,
    minimizeModal,
    permissionCheck,
    quickStart,
}) => {
    const setToastMessage = useContext(ToastMessage)
    const currentUser = useContext(Authentication)
    const { setMinimized, restoredData } = useMinimizer()

    const { username } = currentUser.auth
    const defaultState = {
        formData: {
            manufacturer: row.manufacturerName || '',
            engineModelName: row.engineModelName || '',
            engineModelYear: row.engineModelYear || '',
            updatedAt: row.updatedAt || '',
            updatedBy: row.updatedBy || '',
            archived: row.archived || false,
            id: row.id || '',
        },
        formValidation: {
            manufacturerValidation: false,
            engineModelValidation: false,
            engineModelYearValidation: false,
            isFormDirty: false,
            isFormValid: false,
        },

        closeModal: false,
        manufacturers: [],
        engineModels: [],
        manufacturerOptions: [],
        formCache: {},
        selectedModelFile: undefined,
        permissionsCheck: permissionCheck,
    }

    const reducer = (state, newState) => ({ ...state, ...newState })
    const [state, setState] = useReducer(reducer, defaultState)

    const {
        formData,
        formValidation,
        formCache,
        manufacturerOptions,
        manufacturers,
    } = state

    const { manufacturer, engineModelName, engineModelYear, manufacturerName } =
        formData

    const {
        manufacturerValidation,
        engineModelValidation,
        engineModelYearValidation,
        isFormDirty,
        isFormValid,
    } = formValidation

    const handleSubmit = async () => {
        try {
            const findManufacturer = manufacturers.find(
                ({ manufacturerName }) =>
                    manufacturerName === formData.manufacturer
            )

            const parsedFormData = {
                ...formData,
                manufacturer: findManufacturer,
            }

            let res: any
            {
                res = await addEngineModel(parsedFormData)
                if (res) {
                    const { data, status } = res

                    if (data && status === 200) {
                        setToastMessage(
                            `Engine Model: ${data.engineModelName} was ${
                                row.id ? 'edited' : 'added'
                            } successfully`,
                            'success'
                        )
                        onClose(row.engineModelName, data)
                    } else {
                        responseError(res, row)
                    }
                }
            }
        } catch (err: any) {
            console.error(err, 'error')
            setToastMessage(`${err}`, 'error')
        }
    }

    const handleReset = () =>
        setState({
            formData: defaultState.formData,
            formValidation: defaultState.formValidation,
        })

    const handleChange = (e: any) => {
        const { name, value } = e.target
        setState({
            formData: { ...formData, [name]: value },
        })
        saveCache({ ...formData, [name]: value })
    }

    const getData = async () => {
        let manufacturers: any = []
        let manufacturerOptions: any = []

        try {
            const getManufacturersData = await getManufacturers(true)

            if (getManufacturersData && getManufacturersData.length !== 0) {
                manufacturers = getManufacturersData.filter(
                    (manufacturer) => manufacturer.type === 'engine'
                )
                manufacturerOptions = manufacturers.map(
                    ({ manufacturerName }) => manufacturerName
                )
            }

            setState({
                manufacturers,
                manufacturerOptions,
            })
        } catch (err) {
            console.error(err, 'error')
            setToastMessage(`${err}`, 'error')
        }
    }

    useEffect(() => {
        getData()
        saveCache(row)
    }, [])

    useEffect(() => {
        if (restoredData?.id !== undefined) {
            setState({ row: restoredData, open: true })
        }
    }, [restoredData])

    useEffect(() => {
        if (
            isFormDirty &&
            !manufacturerValidation &&
            !engineModelValidation &&
            !engineModelYearValidation &&
            manufacturer &&
            engineModelName &&
            engineModelYear
        ) {
            setState({
                formValidation: {
                    ...formValidation,
                    isFormValid: true,
                },
            })
        } else {
            setState({
                formValidation: {
                    ...formValidation,
                    isFormValid: false,
                },
            })
        }
    }, [
        isFormDirty,
        manufacturerValidation,
        engineModelValidation,
        engineModelYearValidation,
        manufacturer,
        engineModelName,
        engineModelYear,
    ])

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sx={{ marginBottom: -5 }}>
                <UniversalTagToolbar />
            </Grid>

            <Grid item xs={12} md={8}>
                <FormSection title="Engine Manufacturer Information">
                    <Grid item xs={12}>
                        <Autocomplete
                            disablePortal
                            id="manufacturer"
                            options={manufacturerOptions}
                            value={manufacturer}
                            fullWidth
                            disabled={permissionCheck}
                            onChange={(event, newValue) => {
                                const data = {
                                    target: {
                                        name: 'manufacturer',
                                        value: newValue,
                                    },
                                }
                                handleChange(data)
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Engine Manufacturer"
                                    name="manufacturer"
                                    onChange={handleChange}
                                    required
                                    disabled={permissionCheck}
                                    value={manufacturer}
                                    onBlur={(e: any) =>
                                        basicValidator(
                                            e,
                                            formValidation,
                                            setState
                                        )
                                    }
                                    error={manufacturerValidation}
                                    helperText={
                                        manufacturerValidation
                                            ? 'Please enter a manufacturer name.'
                                            : ''
                                    }
                                />
                            )}
                        />
                    </Grid>
                </FormSection>

                <FormSection title="Engine Model Information">
                    <Grid item xs={12} md={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    required={true}
                                    fullWidth
                                    label="Engine Model"
                                    name="engineModelName"
                                    onChange={handleChange}
                                    value={engineModelName}
                                    onBlur={(e: any) =>
                                        basicValidator(
                                            e,
                                            formValidation,
                                            setState
                                        )
                                    }
                                    error={engineModelValidation}
                                    helperText={
                                        engineModelValidation
                                            ? 'Please enter a hardware part number.'
                                            : ''
                                    }
                                    disabled={permissionCheck}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required={true}
                                    fullWidth
                                    label="Engine Model Year"
                                    name="engineModelYear"
                                    onChange={handleChange}
                                    value={engineModelYear}
                                    onBlur={(e: any) =>
                                        basicValidator(
                                            e,
                                            formValidation,
                                            setState
                                        )
                                    }
                                    error={engineModelYearValidation}
                                    helperText={
                                        engineModelYearValidation
                                            ? 'Please enter a valid numeric year.'
                                            : ''
                                    }
                                    disabled={permissionCheck}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </FormSection>
            </Grid>
            <Grid item xs={12} md={12}>
                <SaveButton
                    handleSubmit={handleSubmit}
                    handleReset={handleReset}
                    disabledSave={!isFormValid || permissionCheck}
                    disabledReset={!isFormDirty || permissionCheck}
                />
            </Grid>
        </Grid>
    )
}

export default EngineModelManagerForm
