import CalibrationFileManagerForm from '../../../../components/Admin/Engines/EngineCalibration/EngineCalibrationForm'
import { Button, Typography, Grid } from '@mui/material'
import { FastForward } from '@mui/icons-material'

const Step1Engine = ({ setNextStep, permissionsCheck }) => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography
                    align="center"
                    variant="h5"
                    color="warning"
                    sx={{ margin: 2 }}
                >
                    If you already have calibration file you can:
                    {
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => setNextStep(2)}
                            sx={{ width: 200, marginLeft: 5 }}
                            endIcon={<FastForward />}
                        >
                            Skip Step
                        </Button>
                    }
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <CalibrationFileManagerForm
                    row={{}}
                    formDescription={'Create New Engine Model'}
                    onClose={() => setNextStep(2)}
                    permissionCheck={permissionsCheck}
                    quickStart={true}
                />
            </Grid>
        </Grid>
    )
}

export default Step1Engine
