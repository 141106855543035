import { useReducer, createContext, useEffect, useContext, useRef } from 'react'
import { initializeApp } from 'firebase/app'
import {
    getAuth,
    signInWithEmailAndPassword,
    signOut,
    onAuthStateChanged,
} from 'firebase/auth'
import { osmosisFirebaseConfig } from '../config/osmosisConfig'
import { useToastMessage } from './ToastPopUpContext'
import { getAuthentication } from '../apiRoutes/authenticationRoutes'
import { useSocket } from './SocketContext'
import { editUser } from '../apiRoutes'

interface ContextValues {
    auth?: any
    setAuth?: (newAuth) => {}
    signIn?: ({ auth, username, password }) => {}
    signOutApp?: (param?: any) => {}
}

interface Preferences {
    boatManagementTable: any[]
}

type Auth = {
    user?: any
    authenticated?: boolean
    preferences?: Preferences
}

type Props = {
    children: React.ReactNode
}

const app = initializeApp(osmosisFirebaseConfig)
export const authConnection = getAuth(app)

export const Authentication = createContext({
    auth: {
        userPreferences: {
            boatManagementTable: [],
            dataTableColumns: {
                devices: {
                    columns: {},
                    density: '',
                },
                engines: {
                    engine: {
                        columns: {},
                        density: '',
                    },
                    engineCalibration: {
                        columns: {},
                        density: '',
                    },
                    engineModel: {
                        columns: {},
                        density: '',
                    },
                    engineManufacturers: {
                        columns: {},
                        density: '',
                    },
                },
                boats: {
                    boatConfig: {
                        columns: {},
                        density: '',
                    },
                    boatModels: {
                        columns: {},
                        density: '',
                    },
                    boatManufacturers: {
                        columns: {},
                        density: '',
                    },
                },
                dealers: {
                    columns: {},
                    density: '',
                },
                system: {
                    osPatchNotes: {
                        columns: {},
                        density: '',
                    },
                    mobilePatchNotes: {
                        columns: {},
                        density: '',
                    },
                },
                users: {
                    userAdmin: {
                        columns: {},
                        density: '',
                    },
                    userNotificationSubscription: {
                        columns: {},
                        density: '',
                    },
                },
                manufacturers: {
                    columns: {},
                    density: '',
                },
            },
        },
        userRole: '',
        userPermissions: {},
        _id: '',
        username: '',
        activeManufacturer: { manufacturerName: '' },
        manufacturer: [],
        authenticated: false,
        loading: true,
        user: {},
    },
    setAuth: (param) => {},
    signIn: (param) => {},
    signOutApp: (param?: any) => {},
    setUser: (param: any) => {},
})
export const AuthenticationContext = ({ children }: Props) => {
    const setToastMessage = useToastMessage()
    const { socket } = useSocket()
    const loading = useRef(false)

    let defaultState = {
        userPreferences: {},
        userRole: '',
        userPermissions: {},
        activeManufacturer: { manufacturerName: '' },
        manufacturer: [],
        _id: '',
        username: '',
        authenticated: false,
        loading: true,
        user: {},
    }
    const reducer = (oldState: any, newState: any) => ({
        ...oldState,
        ...newState,
    })
    const [state, setState] = useReducer(reducer, defaultState)
    const { authenticated, _id } = state

    const setAuth = (newAuth) => {
        setState({ ...newAuth })
    }

    const signIn = async ({ username, password }) => {
        try {
            if (authConnection) {
                if (username && password) {
                    const authenticate = await signInWithEmailAndPassword(
                        authConnection,
                        username,
                        password
                    )

                    if (authenticate) {
                        const { user } = authenticate
                        const { uid } = user
                        if (uid) {
                            const currentSession = await getAuthentication({
                                uid,
                            })
                            if (currentSession) {
                                globalThis.username = currentSession.username
                                setAuth({
                                    ...currentSession,
                                    authenticated: true,
                                })
                            }
                        }
                    }
                }
            }
        } catch (err: any) {
            setToastMessage('Invalid Username or Password', 'error')
            console.error(err)
        }
    }

    const signOutApp = () => {
        try {
            signOut(authConnection)
            setAuth(defaultState)
        } catch (err) {
            console.error(err)
        }
    }

    interface SetUserParams {
        key: string
        value: any
        type?: string
    }

    const setUser = async ({ key, value, type }: SetUserParams) => {
        try {
            let parsedFormData = {}
            if (!type) {
                parsedFormData = { id: _id, ...state.user, [key]: value }
            } else if (type === 'columnData') {
                parsedFormData = {
                    id: _id,
                    ...state.user,
                    userPreferences: {
                        ...state.user.userPreferences,
                        dataTableColumns: {
                            ...state.user.userPreferences?.dataTableColumns,
                            [key]: {
                                ...state.user.userPreferences
                                    .dataTableColumns?.[key],
                                ...value,
                            },
                        },
                    },
                }
            }

            console.log(parsedFormData, 'formData')
            let res: any

            res = await editUser(parsedFormData)
            if (res) {
                const { data, status } = res
                console.log(data)
                if (data && status === 200) {
                    setToastMessage(
                        `User: ${data.userEmail} preferences were edited successfully`,
                        'success'
                    )
                } else {
                    throw new Error('User edit was unsuccessfull')
                }
            }
        } catch (err: any) {
            console.error(err, 'error')
            setToastMessage(`${err.message}`, 'error')
        }
    }

    const getData = async () => {
        try {
            const currentSession = await getAuthentication({
                _id,
            })

            if (currentSession) {
                setAuth({
                    ...currentSession,
                    authenticated: true,
                })
            } else {
                throw new Error('No user found')
            }
        } catch (error) {
            console.log('error')
        }
    }

    useEffect(() => {
        const getLocalAuth = async () => {
            if (authConnection) {
                onAuthStateChanged(authConnection, async (user) => {
                    if (user) {
                        const { uid } = user
                        if (uid) {
                            const currentSession = await getAuthentication({
                                uid,
                            })
                            if (currentSession) {
                                globalThis.username = currentSession.username
                                setAuth({
                                    ...currentSession,
                                    authenticated: true,
                                    loading: false,
                                })
                            }
                        }
                    } else {
                        globalThis.username = ''
                        setAuth({
                            ...defaultState,
                            authenticated: false,
                            loading: false,
                        })
                    }
                })
            }
        }
        getLocalAuth()
    }, [])

    useEffect(() => {
        if (authenticated && _id) {
            socket.on(_id, () => {
                getData()
            })
        }
    }, [authenticated, _id])
    console.log(state)
    return (
        <Authentication.Provider
            value={{
                auth: state,
                setAuth,
                signIn,
                signOutApp,
                setUser,
            }}
        >
            {children}
        </Authentication.Provider>
    )
}

export const useAuth = () => useContext(Authentication)
export const usePermissions = () => {
    const currentUser = useContext(Authentication)
    const { userPermissions, userRole, activeManufacturer, userPreferences } =
        currentUser.auth
    return {
        ...userPermissions,
        userRole,
        activeManufacturer,
        ...userPreferences,
    }
}
