import {
    Grid,
    Typography,
    Paper,
    Autocomplete,
    TextField,
    IconButton,
    Button,
} from '@mui/material'
import Box from '@mui/material/Box'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import * as parse from 'autosuggest-highlight/parse'
import { debounce } from '@mui/material/utils'
import { useEffect, useMemo, useRef, useState } from 'react'
import axios from 'axios'
import { osmosisGoogleMapApi } from '../../config/osmosisConfig'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import { clear } from '@testing-library/user-event/dist/clear'

interface UniversalFormSectionTypes {
    title?: string
    icon?: any
    size?: number
    save?: any
    reset?: any
    titleBottom?: any
    titleAlignment?: 'left' | 'center' | 'right'
    titleBottomAlignment?: 'left' | 'center' | 'right'
    spacing?: number
    sx?: {}
    children?: React.ReactNode
}

export const UniversalFormSection = ({
    title,
    size = 12,
    titleAlignment = 'left',
    spacing = 2,
    sx = { padding: 3 },
    children,
    icon,
    save,
    reset,
    titleBottom,
    titleBottomAlignment = 'center',
}: UniversalFormSectionTypes) => {
    return (
        <Grid item xs={size} sx={{ marginBottom: 2 }}>
            <Paper sx={sx} elevation={4}>
                <Grid container spacing={spacing}>
                    <Grid
                        container
                        sx={{
                            marginBottom: -2,
                            justifyContent: 'flex-end',
                        }}
                    >
                        <Grid item xs={3}>
                            {save}
                        </Grid>
                    </Grid>

                    <Grid item xs={12} sx={{ marginTop: -1 }}>
                        <Typography
                            align={titleAlignment}
                            variant="h5"
                            color={'primary'}
                        >
                            {icon}
                            {title}
                        </Typography>
                    </Grid>

                    {children}

                    <Grid item xs={12} sx={{ marginTop: 1 }}>
                        <Typography
                            align={titleBottomAlignment}
                            variant="h5"
                            color={'primary'}
                        >
                            {titleBottom}
                        </Typography>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    )
}

interface UniversalAutoCompleteProps {
    options: string[]
    value: any
    handleChange: any
    label: string
    name: string
    error?: boolean
    helperText?: string
    required?: boolean
    id?: string
    fullWidth?: boolean
    sx?: any
    onBlur?: any
    InputProps?: any
    getOptionLabel?: any
    disabled?: boolean
    clearFunction?: any
}

export const UniversalAutoComplete = ({
    options,
    value,
    handleChange,
    onBlur,
    label,
    name,
    error,
    required,
    helperText,
    id,
    sx,
    InputProps,
    fullWidth = true,
    getOptionLabel,
    disabled = false,
    clearFunction,
}: UniversalAutoCompleteProps) => {
    return (
        <Autocomplete
            disablePortal
            id={id}
            options={options}
            value={value}
            onBlur={onBlur}
            disabled={disabled}
            filterSelectedOptions
            disableClearable={disabled || !!clearFunction}
            {...getOptionLabel}
            onChange={(event, newValue) => {
                const data = {
                    target: {
                        name,
                        value: newValue,
                    },
                }
                handleChange(data)
            }}
            fullWidth={fullWidth}
            sx={sx}
            renderInput={(params) => (
                <TextField
                    {...params}
                    name={name}
                    label={label}
                    helperText={helperText}
                    error={error}
                    required={required}
                    variant="outlined"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment:
                            clearFunction &&
                            params.inputProps.value &&
                            !disabled ? (
                                <>
                                    {params.InputProps.endAdornment}
                                    <IconButton onClick={clearFunction}>
                                        <HighlightOffIcon />
                                    </IconButton>
                                </>
                            ) : (
                                params.InputProps.endAdornment
                            ),
                    }}
                />
            )}
        />
    )
}

interface UniversalTitle {
    title: string
    variant?: any
    color?: 'primary' | 'secondary'
    sx?: {}
    align?: 'center' | 'left' | 'right' | 'inherit' | 'justify'
}

export const UniversalTitle = ({
    title,
    variant = 'h1',
    color = 'primary',
    sx = { margin: 2 },
    align = 'center',
}: UniversalTitle) => {
    return (
        <Typography variant={variant} color={color} align={align} sx={sx}>
            {title}
        </Typography>
    )
}

// Universal Google Maps Autocomplete

function loadScript(src: string, position: HTMLElement | null, id: string) {
    if (!position) {
        return
    }

    const script = document.createElement('script')
    script.setAttribute('async', '')
    script.setAttribute('id', id)
    script.src = src
    position.appendChild(script)
}

const autocompleteService = { current: null }

interface MainTextMatchedSubstrings {
    offset: number
    length: number
}

interface terms {
    offset: number
    value: string
}

interface StructuredFormatting {
    main_text: string
    secondary_text: string
    main_text_matched_substrings?: readonly MainTextMatchedSubstrings[]
}

interface PlaceType {
    description: string
    structured_formatting: StructuredFormatting
    terms: readonly terms[]
    place_id: string
}

interface UniversalGoogleMapsProps {
    value: any
    onBlur?: any
    label: string
    name?: string
    id?: string
    fullWidth?: boolean
    sx?: any
    onChange?: any
    onClick?: any
    clearFunction?: any
    disabled?: any
}

export const UniversalGoogleMapsAc = ({
    value,
    onChange,
    name,
    sx,
    id,
    fullWidth,
    onClick,
    label,
    onBlur,
    clearFunction,
    disabled,
}: UniversalGoogleMapsProps) => {
    const [valueMap, setValue] = useState<PlaceType | null>(null)
    const [inputValue, setInputValue] = useState('')
    const [options, setOptions] = useState<readonly PlaceType[]>([])
    const loaded = useRef(false)

    if (typeof window !== 'undefined' && !loaded.current) {
        if (!document.querySelector('#google-maps')) {
            loadScript(
                `https://maps.googleapis.com/maps/api/js?key=${osmosisGoogleMapApi}&libraries=places`,
                document.querySelector('head'),
                'google-maps'
            )
            loaded.current = true
        }
    }

    const fetch = useMemo(
        () =>
            debounce(
                (
                    request: { input: string },
                    callback: (results?: readonly PlaceType[]) => void
                ) =>
                    (autocompleteService.current as any).getPlacePredictions(
                        request,
                        callback
                    ),
                400
            ),
        []
    )

    useEffect(() => {
        let active = true

        if (!autocompleteService.current && (window as any).google) {
            autocompleteService.current = new (
                window as any
            ).google.maps.places.AutocompleteService('places')
        }
        if (!autocompleteService.current) {
            return undefined
        }

        if (inputValue === '') {
            setOptions(valueMap ? [valueMap] : [])
            return undefined
        }

        fetch({ input: inputValue }, (results?: readonly PlaceType[]) => {
            if (active) {
                let newOptions: readonly PlaceType[] = []

                if (valueMap) {
                    newOptions = [valueMap]
                }

                if (results) {
                    newOptions = [...newOptions, ...results]
                }

                setOptions(newOptions)
            }
        })

        return () => {
            active = false
        }
    }, [valueMap, inputValue, fetch])

    const fetchLocationDetails = async (address: any) => {
        if (address?.description !== 'undefined') {
            const newDescription = address?.description.replaceAll(' ', '+')

            try {
                const { data } = await axios.get(
                    `https://maps.googleapis.com/maps/api/geocode/json?address=${newDescription}&key=${osmosisGoogleMapApi} `
                )

                if (data && data?.results !== 0) {
                    const placeInfo = data?.results[0]
                    let location = {
                        lat: 0,
                        lng: 0,
                        street: '',
                    }
                    if (placeInfo && placeInfo?.geometry) {
                        location = {
                            lat: placeInfo?.geometry?.location?.lat,
                            lng: placeInfo?.geometry?.location?.lng,
                            // zip: placeInfo?.address_components[7].long_name,
                            // city: placeInfo?.address_components[3].long_name,
                            // county: placeInfo?.address_components[4].long_name,
                            // country: placeInfo?.address_components[6].short_name,
                            // state: placeInfo?.address_components[5].short_name,
                            street: placeInfo?.formatted_address,
                        }
                    }

                    return location
                }
            } catch (error: any) {
                console.error('Error fetching location:', error.message)
            }
        }
    }

    const handleOnChange = async (event: any, newValue: PlaceType | null) => {
        try {
            const location = await fetchLocationDetails(newValue)
            if (
                location &&
                location?.lat &&
                location?.lng &&
                location?.street
            ) {
                if (newValue) {
                    setOptions([newValue, ...options])
                    setValue(newValue)
                    onChange({ newValue, location })
                } else {
                    setOptions(options)
                    onChange({ newValue, location })
                }
            }
        } catch (error: any) {
            console.error(error)
        }
    }

    return (
        <Grid>
            <Autocomplete
                id={id}
                sx={sx}
                getOptionLabel={(option) =>
                    typeof option === 'string' ? option : option.description
                }
                filterOptions={(x) => x}
                options={options}
                autoComplete
                onBlur={onBlur}
                includeInputInList
                filterSelectedOptions
                onClick={onClick}
                fullWidth={fullWidth}
                value={value}
                disableClearable
                disabled={disabled}
                noOptionsText="Enter Location For Suggestions"
                onChange={handleOnChange}
                onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue)
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={label}
                        fullWidth={fullWidth}
                        name={name}
                        onChange={onChange}
                        value={value}
                        disabled={disabled}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <>
                                    {params.InputProps.endAdornment}
                                    {params.inputProps.value && (
                                        <IconButton onClick={clearFunction}>
                                            <HighlightOffIcon />
                                        </IconButton>
                                    )}
                                </>
                            ),
                        }}
                    />
                )}
                renderOption={(props, option) => {
                    const matches =
                        option.structured_formatting
                            .main_text_matched_substrings || []

                    const parts = parse(
                        option.structured_formatting.main_text,
                        matches.map((match: any) => [
                            match.offset,
                            match.offset + match.length,
                        ])
                    )

                    return (
                        <li {...props}>
                            <Grid container alignItems="center">
                                <Grid item sx={{ display: 'flex', width: 44 }}>
                                    <LocationOnIcon
                                        sx={{ color: 'text.secondary' }}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    sx={{
                                        width: 'calc(100% - 44px)',
                                        wordWrap: 'break-word',
                                    }}
                                >
                                    {parts.map((part, index) => (
                                        <Box
                                            key={index}
                                            component="span"
                                            sx={{
                                                fontWeight: part.highlight
                                                    ? 'bold'
                                                    : 'regular',
                                            }}
                                        >
                                            {part.text}
                                        </Box>
                                    ))}
                                    <Typography
                                        variant="body2"
                                        color="text.secondary"
                                    >
                                        {
                                            option.structured_formatting
                                                .secondary_text
                                        }
                                    </Typography>
                                </Grid>
                            </Grid>
                        </li>
                    )
                }}
            />
        </Grid>
    )
}
interface UniversalSaveButtonProps {
    handleSubmit: (e: any) => void
    handleReset: () => void
    disabledSave: boolean
    disabledReset: boolean
    saveTitle?: string
    resetTitle?: string
    title?: string
    titleAlignment?: 'center' | 'left' | 'right'
    saveStyle?: {}
    resetStyle?: {}
    saveVariant?: 'contained' | 'text' | 'outlined'
    resetVariant?: 'contained' | 'text' | 'outlined'
    containerStyle?: {}
    saveColor?: 'primary' | 'secondary' | 'error'
    resetColor?: 'primary' | 'secondary' | 'error'
    saveSize?: number
    resetSize?: number
    quickStart?: boolean
}
export const UniversalSaveButton = ({
    handleSubmit,
    handleReset,
    disabledSave,
    disabledReset,
    saveTitle,
    resetTitle,
    title,
    titleAlignment,
    saveStyle,
    resetStyle,
    saveVariant,
    resetVariant,
    containerStyle,
    saveColor,
    resetColor,
    saveSize,
    resetSize,
    quickStart,
}: UniversalSaveButtonProps) => {
    return (
        <UniversalFormSection
            title={title || ''}
            titleAlignment={titleAlignment || 'center'}
            sx={{ ...containerStyle, marginTop: 2 }}
        >
            <Grid item xs={12}>
                <Grid
                    container
                    spacing={2}
                    sx={{
                        alignContent: 'center',
                        paddingLeft: 2,
                        paddingRight: 2,
                    }}
                >
                    <Grid item xs={12} md={saveSize || 8}>
                        <Button
                            variant={saveVariant || 'contained'}
                            color={saveColor || 'primary'}
                            onClick={handleSubmit}
                            disabled={disabledSave}
                            fullWidth
                            sx={{
                                height: 55,
                                ...saveStyle,
                            }}
                        >
                            {(quickStart && 'Save & Go To Next Step') ||
                                saveTitle ||
                                'Save & Close'}
                        </Button>
                    </Grid>
                    <Grid item xs={12} md={resetSize || 4}>
                        <Button
                            variant={resetVariant || 'contained'}
                            color={resetColor || 'error'}
                            onClick={handleReset}
                            disabled={disabledReset}
                            fullWidth
                            sx={{
                                height: 55,
                                ...resetStyle,
                            }}
                        >
                            {resetTitle || 'reset'}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </UniversalFormSection>
    )
}
