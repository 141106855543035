import { useContext } from 'react'

import { NavMenuContext } from './context/NavMenuContext'

import { ThemeBuilder } from './context/ThemeBuilderContext'
import { AuthenticationContext } from './context/AuthenticationContext'
import { VersionCheckerContext } from './context/VersionCheckerContext'
import { ToastMessagePopup } from './context/ToastPopUpContext'
import { SocketContext } from './context/SocketContext'
import { OzChatContext } from './context/OzChatContext'
import { MinimizerContext } from './context/MinimizeContext'
import { QuickStartWizardContext } from './context/QuickStartWizard'
const App = () => {
    return (
        <ThemeBuilder>
            <ToastMessagePopup>
                <SocketContext>
                    <AuthenticationContext>
                        <VersionCheckerContext>
                            <MinimizerContext>
                                <OzChatContext>
                                    <QuickStartWizardContext>
                                        <NavMenuContext />
                                    </QuickStartWizardContext>
                                </OzChatContext>
                            </MinimizerContext>
                        </VersionCheckerContext>
                    </AuthenticationContext>
                </SocketContext>
            </ToastMessagePopup>
        </ThemeBuilder>
    )
}

export default App
