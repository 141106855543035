import { Grid, Typography } from '@mui/material'
import {
    Delete as DeleteIcon,
    Archive as ArchiveIcon,
    ArrowOutward as ArrowOutwardIcon,
    AddBox as AddBoxIcon,
    Restore,
    DeleteForever,
    ContentCopy,
} from '@mui/icons-material/'
import DirectionsBoatFilledIcon from '@mui/icons-material/DirectionsBoatFilled'
import react, { useContext, useEffect, useReducer } from 'react'
import {
    FormSection,
    UniversalDataTable,
    UniversalLoadingCircle,
    UniversalModal,
    UniversalToolBar,
} from '../../../UniversalComponents'
import BoatModelForm from './boatModelForm'
import { ToastMessage } from '../../../../context/ToastPopUpContext'
import { useMinimizer } from '../../../../context/MinimizeContext'
import {
    Authentication,
    useAuth,
} from '../../../../context/AuthenticationContext'
import {
    getBoatModels,
    deleteBoatModel,
    deactivateBoatModels,
    cloneBoatModels,
} from '../../../../apiRoutes'
import {
    handleActivation,
    handleClone,
    handleDeletion,
    standardColumnMapper,
} from '../../../../utilities/functionTools'
import { usePermissions } from '../../../../context/AuthenticationContext'
import { permissionsCheck } from '../../../../utilities/functionTools'

const today = new Date().toISOString().substring(0, 10)

const BoatModelIndex = ({ restoredData }) => {
    const reducer = (state, newState) => ({ ...state, ...newState })

    const currentUser = useAuth()
    const { setUser } = useAuth()
    const { username, userRole, activeManufacturer, userPreferences, _id } =
        currentUser.auth

    const defaultState = {
        open: restoredData.id === undefined ? false : true,
        row: { manufacturer: { manufacturerName: activeManufacturer } },
        formCache: {},
        boatModels: [],
        viewArchived: false,
        archive: [],
        filteredBoatModels: [],
        viewDeactivated: false,
        activateData: [],
        data: [],
        boats: {
            boatModels: {
                columns: [
                    {
                        field: 'modelYear',
                        headerName: 'MODEL YEAR',
                        width: 150,
                    },
                    {
                        field: 'manufacturerName',
                        headerName: 'MANUFACTURER',
                        width: 150,
                    },
                    {
                        field: 'model',
                        headerName: 'MODEL',
                        width: 150,
                    },
                ],
                density: 'standard',
            },
        },
        density:
            userPreferences?.dataTableColumns?.boats?.boatModels?.density ||
            'standard',
    }

    const setToastMessage = useContext(ToastMessage)
    const [state, setState] = useReducer(reducer, defaultState)
    const { setMinimized } = useMinimizer()

    const userPermissions = usePermissions()

    const {
        open,
        row,
        formCache,
        viewArchived,
        archive,
        filteredBoatModels,
        viewDeactivated,
        data,
        activateData,
        density,
        boats,
    } = state

    const { boatModels } = boats

    const getData = async (active) => {
        try {
            const boatModels = await getBoatModels(active)
            if (boatModels && boatModels.length !== 0) {
                const models = standardColumnMapper(defaultColumns, boatModels)

                setState({ data: models })
            } else {
                setState({ data: [] })
            }
        } catch (err: any) {
            setToastMessage(err, 'error')
            console.error(err, 'error')
        }
    }

    const collectColumnData = () => {
        const boats =
            userPreferences?.dataTableColumns?.boats?.boatModels?.columns

        if (Array.isArray(boats)) {
            const mapColumns = boats.map((boat) => ({
                field: boat?.field,
                headerName: boat?.headerName,
                width: boat?.width,
                isVisible: boat.isVisible,
            }))

            const format = {
                boatModels: {
                    columns: mapColumns,
                },
            }

            setState({ ...state, boats: format })
        } else {
            console.log('No Boats Found')
        }
    }
    const submitColumnData = async () => {
        try {
            setUser({
                key: 'boats',
                value: boats,
                type: 'columnData',
            })
        } catch (err: any) {
            console.error(err, 'error')
            setToastMessage(`${err}`, 'error')
        }
    }

    const handleActivateData = () => {
        handleActivation({
            activateData,
            data,
            returnKey: 'model',
            setState,
            getData,
            route: deactivateBoatModels,
            viewDeactivated,
            setToastMessage,
            page: 'boatModels',
        })
    }

    const handleDeletionData = () => {
        handleDeletion({
            activateData,
            data,
            returnKey: 'model',
            setState,
            getData,
            route: deleteBoatModel,
            viewDeactivated,
            setToastMessage,
            page: 'boatModels',
        })
    }

    const handleDeletionCheck = () => {
        setToastMessage(
            `Are you sure you want to delete these Models?`,
            'warning',
            handleDeletionData
        )
    }

    const handleCloneData = () => {
        handleClone({
            cloneData: activateData,
            data,
            returnKey: 'model',
            setState,
            getData,
            route: cloneBoatModels,
            viewDeactivated,
            setToastMessage,
            page: 'boatModels',
        })
    }

    const handleCloneCheck = () => {
        if (activateData && activateData.length !== 0) {
            setToastMessage(
                `Are you sure you want to clone this Boat Model?`,
                'warning',
                handleCloneData
            )
        } else {
            setToastMessage(`Please select Model to clone from table.`, 'error')
        }
    }

    const setDeactivatedView = () => {
        setState({ viewDeactivated: !viewDeactivated })
    }

    useEffect(() => {
        getData(true)
        collectColumnData()
        setMinimized(false, restoredData.id, true)
    }, [])

    useEffect(() => {
        getData(!viewDeactivated)
    }, [viewDeactivated, activeManufacturer, userRole])

    const setModalVisbility = async (tableRow: any, newData: any) => {
        if (tableRow?.model) {
            setState({ row: tableRow, open: !open })
        } else {
            setState({ row: defaultState.row, open: !open })
        }
        if (newData !== undefined) {
            return getData(true)
        }
    }

    const minimizeModal = () => {
        setMinimized(true, {
            title: formCache.model
                ? `Edit: ${formCache.model}`
                : 'Add New Boat Model',
            link: '/admin/boats',
            data: formCache.id
                ? { ...formCache, tab: 'model' }
                : { ...formCache, id: 'newModel', tab: 'model' },
            id: formCache.id ? formCache.id : 'newModel',
        })
        setState({ open: false })
    }

    const defaultColumns = [
        'manufacturer.manufacturerName',
        'modelYear',
        'model',
        'archived',
    ]

    const buttons = [
        {
            buttonName: 'Add Boat Model',
            buttonIcon: <DirectionsBoatFilledIcon />,
            buttonFunction: () => setState({ open: !open }),
            disabled: permissionsCheck(userPermissions, 'Boats'),
        },
        {
            buttonName: 'Clone Boat Model',
            buttonIcon: <ContentCopy />,
            buttonFunction: () => handleCloneCheck(),
            disabled: permissionsCheck(userPermissions, 'Boats'),
        },
        {
            buttonName: !viewDeactivated ? 'Deactivate' : 'Activate',
            buttonIcon: <ArchiveIcon />,
            buttonFunction: handleActivateData,
            disabled: permissionsCheck(userPermissions, 'Boats'),
        },
        {
            buttonName: viewDeactivated
                ? 'View Active Models'
                : 'View Deactivated Models',
            buttonIcon: <Restore />,
            buttonFunction: setDeactivatedView,
        },
        {
            buttonName: 'Delete',
            buttonIcon: <DeleteForever />,
            buttonFunction: handleDeletionCheck,
            disabled: permissionsCheck(userPermissions, 'Super Admin'),
            display: !viewDeactivated ? 'none' : null,
        },
        // {
        //     buttonName: 'Export',
        //     buttonIcon: <ArrowOutwardIcon />,
        //     buttonFunction: () => {},
        // },
    ]

    const handleUpdatedColumnsChange = (updatedColumns) => {
        const format = updatedColumns.map((col) => ({
            field: col.field,
            headerName: col.headerName,
            width: col.width,
            isVisible: col.isVisible,
        }))

        const setDensity = state.density

        const setData = { boatModels: { columns: format, density: setDensity } }

        setState({
            boats: setData,
        })
    }

    const handleDensityChange = (newDensity) => {
        setState({
            density: newDensity,
            boats: {
                boatModels: {
                    ...boatModels,
                    density: newDensity,
                },
            },
        })
    }

    return (
        <Grid container>
            <Grid container>
                <UniversalToolBar buttons={buttons} />
            </Grid>
            <Grid container sx={{ marginTop: 2, marginBottom: -10 }}>
                <FormSection>
                    <Grid item xs={12}>
                        <Typography
                            variant="h4"
                            color={'primary'}
                            align="center"
                            sx={{ marginTop: -1 }}
                        >
                            {!viewDeactivated
                                ? 'Boat Models'
                                : 'Deactivated Boat Models'}
                        </Typography>
                    </Grid>
                </FormSection>
            </Grid>

            <Grid item xs={12}>
                <UniversalLoadingCircle
                    data={data}
                    reloadFunction={
                        !viewDeactivated
                            ? () => getData(true)
                            : () => getData(false)
                    }
                    customTimeoutMessage={
                        !!viewDeactivated && 'No Deactivated Models Available'
                    }
                >
                    <UniversalDataTable
                        data={data}
                        apiDensity={density}
                        apiColumns={boats?.boatModels?.columns}
                        savePreferences={() => submitColumnData()}
                        visibleColumns={[]}
                        getRowData={(tableRow) =>
                            setModalVisbility(tableRow, undefined)
                        }
                        onRowSelectionModelChange={(newSelection) => {
                            setState({
                                activateData: newSelection,
                            })
                        }}
                        onUpdatedColumnsChange={handleUpdatedColumnsChange}
                        onDensityChange={handleDensityChange}
                    />
                </UniversalLoadingCircle>

                <UniversalModal
                    title={
                        row.id
                            ? ` Editing Model  ${row.model} `
                            : ' Create New Boat Model'
                    }
                    open={open}
                    onClose={(tableRow) =>
                        setModalVisbility(tableRow, undefined)
                    }
                    wrapperStyle={{
                        margin: 'auto',
                        width: '90%',
                        marginTop: 5,
                        marginBottom: 5,
                    }}
                    timeStamp={`${
                        row.updatedBy
                            ? ` ${
                                  row.updatedBy
                                      ? ` Modified By ${row.updatedBy}`
                                      : ''
                              } ${
                                  row.updatedAt
                                      ? `| Modified Date ${row.updatedAt.substring(
                                            0,
                                            10
                                        )}`
                                      : ''
                              }  `
                            : ` Modified By: ${username} | Modified Date: ${today} `
                    }`}
                    onMinimize={minimizeModal}
                    draggable={true}
                    minimizable={true}
                >
                    <BoatModelForm
                        row={row}
                        formDescription="Add a new Boat Model"
                        onClose={(tableRow: any, newData: any) =>
                            setModalVisbility(tableRow, newData)
                        }
                        saveCache={(data: any) => setState({ formCache: data })}
                        permissionCheck={permissionsCheck(
                            userPermissions,
                            'Boats'
                        )}
                        quickStart={false}
                    />
                </UniversalModal>
            </Grid>
        </Grid>
    )
}

export default BoatModelIndex
